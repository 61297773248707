import React from "react";
import PageHeader from "../components/headers/PageHeader";
// import { motion, AnimatePresence } from "framer-motion";
import { useState ,useEffect } from "react";
import VolunteeringCard from "../components/cards/VolunteeringCard";

const projects = [
  {
    id: 1,
    name: "IEEEXtreme 17.0",
    highlight: "Ambassador, Participant, Public Visibility Team Lead",
    des: "As the Public Visibility Team Lead for the IEEEXtreme 17.0 Sri Lanka section Organizing Committee and Uva Wellassa University Ambassador, I play a crucial role in promoting the event and engaging the university community. My responsibilities include enhancing the visibility of IEEEXtreme, driving participation, and representing Uva Wellassa University. By leveraging various communication channels and organizing promotional activities, I aim to create a vibrant and competitive environment for this global coding competition.",
    image: "assests/images/volunteering/ambasodor-min.jpg",
  },
  {
    id: 2,
    name: "Impetus Magazine 2023",
    highlight: "Design Team Lead, Magazine Design, Graphic Design, Motion Graphic Design",
    des: "As the Design Team Lead for Impetus Magazine 2023, I oversee the magazine's visual and graphic design elements. My expertise includes magazine design, graphic design, and motion graphic design, ensuring that the publication is visually appealing and engaging. I lead a team of designers in creating compelling layouts, vibrant graphics, and dynamic motion graphics that enhance the reader's experience and effectively convey the magazine's content.",
    image: "assests/images/volunteering/impetus-2023.jpg",
  },
  {
    id: 3,
    name: "WebDevPro 2023-2024",
    highlight: "Web Team Lead, Web Development, Graphic Design",
    des: "As the Web Team Lead at WebDevPro 2023-2024, I specialize in web development and graphic design, ensuring visually appealing and user-friendly websites. I lead the Official Web Designing Project for the Rotaract Club of Uva Wellassa University, where I oversee the creation and maintenance of the club's online presence. My responsibilities include designing engaging interfaces, optimizing site performance, and collaborating with team members to deliver high-quality web solutions that enhance the club’s visibility and engagement.",
    image: "assests/images/volunteering/web-dev-pro.jpg",
  },
  {
    id: 4,
    name: "Innovitriz 2023",
    highlight: "Project Chair, Graphic Design, Motion Graphic Design",
    des: "As the Project Chair of InovitroZ 2023, an innovation-focused session series organized by the Rotaract Club of Uva Wellassa University, I lead the initiative to inspire and nurture creative ideas and innovative thinking among participants. My role involves planning, coordinating, and executing various sessions that provide a platform for students to present their innovative projects and ideas. By fostering a collaborative environment, we aim to drive technological advancements and entrepreneurial spirit within the university community",
    image: "assests/images/volunteering/innovitroz-2023.jpg",
  },
  {
    id: 5,
    name: "CST LAN Challenge",
    highlight: "Design Team Lead, Official website, Graphic Design, Motion Graphic Design",
    des: "As the Design Team Lead for the CST LAN Challenge, a premier gaming competition, I oversee the creation and management of the official website, graphic design, and motion graphic design. My role involves crafting visually captivating and user-friendly digital experiences that engage participants and audiences alike. I lead a talented team to develop dynamic graphics and motion elements that enhance the event's online presence and overall appeal.",
    image: "assests/images/personal/project-5.jpg",
  },
  {
    id: 6,
    name: "IEEE Open Day 2023",
    highlight: "Design Team Lead, Graphic Design, Motion Graphic Design",
    des: "As the Design Team Lead for IEEE Open Day 2023 at Uva Wellassa University, I am responsible for overseeing graphic design and motion graphic design. My role includes creating visually appealing promotional materials and dynamic motion graphics to engage and inform attendees. By leading a skilled team, I ensure that all design elements effectively communicate the event's theme and enhance the overall attendee experience.",
    image: "assests/images/volunteering/ieee-openday-2023.jpg",
  },
  // {
  //   id: 7,
  //   name: "Token G10 |Java Project | 1st Year",
  //   highlight: "JavaFX, Java, Hibernate",
  //   des: "The ongoing crisis in Sri Lanka, marked by a severe shortage of foreign currency, has led to skyrocketing prices of essential goods, particularly food, plunging many into hunger. Fuel restrictions have cascading effects, disrupting livelihoods and causing chaos in transportation. The introduction of Token G07 aims to alleviate the lengthy fuel queues, providing a vital solution to a pressing issue.",
  //   image: "assests/images/university-projects/tokenG10.png",
  // },
  // {
  //   id: 8,
  //   name: "Western University | 1st Year",
  //   highlight: "HTML, CSS, JavaScript",
  //   des: "Embark on a dynamic journey with our 1st Year Web Development Project at Western University. Utilizing HTML, CSS, and JavaScript, we're crafting an interactive virtual campus experience. Seamlessly blending design and functionality, our project promises to enhance accessibility and engagement for students, faculty, and the broader university community. Join us as we redefine the digital landscape of academia.",
  //   image: "assests/images/university-projects/western-university.jpg",
  //   github: "https://github.com/yashEra/Western-University",
  //   preview: "https://western-university.onrender.com/",
  // },
];
const VolunteeringProjects = (work) => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div>
      <PageHeader heading="Volunteering Projects" />
      <div className="flex justify-center px-[5%] md:px-[10%] pt-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-[1140px]  justify-center pb-8 text-justify">
          {projects.map((work, i) => (
            // <motion.div
            //   layoutId={work.id}
            //   onClick={() => setSelectedId(work.id)}
            // >
              <VolunteeringCard
                name={work.name}
                highlight={work.highlight}
                des={work.des}
                image={work.image}
                github={work.github}
                preview={work.preview}
              />
            // </motion.div>
          ))}

        </div>
      </div>
      {/* <AnimatePresence>
            {selectedId && (
              <motion.div layoutId={selectedId}>
                <ProjectCard
                  name={work.name}
                  highlight={work.highlight}
                  des={work.des}
                  image={work.image}
                  github={work.github}
                  preview={work.preview}
                />

                <motion.button onClick={() => setSelectedId(null)} />
              </motion.div>
            )}
          </AnimatePresence> */}
    </div>
  );
};

export default VolunteeringProjects;
