import React from "react";

const Timeline = () => {
  return (
    <div className="md:p-8 p-4">
      {/*<!-- Component: Alternative Changelog feed --> */}
      <ul
        aria-label="Alternative changelog feed"
        role="feed"
        className="max-w-[1000px] relative flex flex-col gap-12 py-12 pl-6 text-sm before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 lg:pl-0 lg:before:left-[10rem] lg:after:left-[10rem]"
      >
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2021 - Present
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2021 - Present</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              BSc(Hons). in Computer Science and Technology{" "}
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            {/* <p className=" text-slate-500">
              Uva Wellassa University of Sri Lanka
            </p> */}
            <p className=" text-slate-500">
              Undergraduate of Bachelor of Science (BSc) in Computer Science and
              Technology, Uva Wellassa University of Sri Lanka
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2020 - Present
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2020 - Present</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              Bachelor of Information Technology{" "}
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            {/* <p className=" text-slate-500">
              University of Colombo School of Computing
            </p> */}
            <p className=" text-slate-500">
              Undergraduate of Bachelor of Information Technology, University of
              Colombo School of Computing
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2004 - 2018
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2004 - 2018</p>

            <h4 className="text-base font-medium text-[#0052D4]">
              General Certificate of Education {"(Advanced Level)"}
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h4>
            <p className=" text-slate-500">Siridhamma College - Galle</p>
            <p className=" text-slate-500">
              Physical Science
              (Combined Maths, Chemistry & Physics)
            </p>
            <ul className="pl-5 list-disc text-slate-500 marker:text-[#0052D4]">
              <li>Prasident of The Quiz Club</li>
              <li>Senior Member of Western Band</li>
              <li>Senior Member of Scout Group</li>
              <li>Senior Member of Chess Team</li>
            </ul>
          </div>
        </li>
      </ul>
      {/*<!-- End Alternative Changelog feed --> */}
    </div>
  );
};

export default Timeline;
