import React from "react";

const VolunteeringTimeline = () => {
  return (
    <div className="md:p-8 p-4">
      {/*<!-- Component: Alternative Changelog feed --> */}
      <ul
        aria-label="Alternative changelog feed"
        role="feed"
        className="max-w-[1000px] relative flex flex-col gap-12 py-12 pl-6 text-sm before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 lg:pl-0 lg:before:left-[10rem] lg:after:left-[10rem]"
      >
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023 - 2024
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023 - 2024</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              Creative Content Coodinator
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            <p className=" text-slate-500 font-medium">
              IEEE Sri Lanka Section Student Activity Committee
            </p>
            <p className=" text-slate-500">
              As a Creative Content Coordinator, I am currently serving as the
              Collaborative Activities Sub-committee in the SLSAC. My
              responsibilities include planning and managing PR, designing
              visual elements, and content writing to contribute to the overall
              success of our projects.
            </p>
          </div>
        </li>
        {/*  */}
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              IEEEXtreme 17.0 Ambassador
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            <p className=" text-slate-500 font-medium">
              Uva Wellassa University of Sri Lanka
            </p>
            <p className=" text-slate-500">
              IEEEXtreme is an annual global programming competition organized
              by IEEE, unique and highly challenging coding competition designed
              to test the problem-solving skills, algorithmic thinking, and
              teamwork capabilities of participating teams.
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023 - 2024
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023 - 2024</p>
            <h4 className="text-base font-medium text-[#0052D4]">
              Director of Public Relations and Digital Services
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h4>
            <p className=" text-slate-500 font-medium">
              Rotaract Club of Uva Wellassa University - Badulla
            </p>
            <p className=" text-slate-500">
              As the Director of Public Relations and Digital Services at
              Rotaract Club of Uva Wellassa University - Badulla, I manage
              communications, promote activities, and enhance the {"club's"}{" "}
              online presence to engage members and the community effectively.
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023 - 2024
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023 - 2024</p>
            <h4 className="text-base font-medium text-[#0052D4]">
              Assistant Editor
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h4>
            <p className=" text-slate-500 font-medium">
              IEEE Uva Wellassa University Student Branch
            </p>
            <p className=" text-slate-500">
              As an Assistant Editor for the IEEE Uva Wellassa University
              Student Branch, my role involves contributing to the planning,
              production, and editing of various publications and communication
              materials related to IEEE activities and events.
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023 - 2024
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023 - 2024</p>
            <h4 className="text-base font-medium text-[#0052D4]">
              Design Team Member
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h4>
            <p className=" text-slate-500 font-medium">
              FOSS Community of Uva Wellassa University
            </p>
            <p className=" text-slate-500">
              As a member of the Design Team at FOSS Community Uva Wellassa
              University - Badulla, I collaborate on creating visually engaging
              and innovative designs to promote club acttivies, events and
              enhance the {"community's"} brand identity.
            </p>
          </div>
        </li>
      </ul>
      {/*<!-- End Alternative Changelog feed --> */}
    </div>
  );
};

export default VolunteeringTimeline;
