import React from "react";
import Tooltip from "../tooltip/Tooltip";

const IconsCard = (props) => {
  return (
    <Tooltip text={props.tooltip}>
      <div className="p-2 flex items-center">
        <img
          className="h-auto w-full object-cover object-center  hover:scale-110 duration-300"
          src={props.image}
          alt="Icon Picture"
        />
      </div>{" "}
    </Tooltip>
  );
};

export default IconsCard;
