import {React , useEffect} from "react";
import ServiceCard from "../components/cards/ServiceCard";
import PageHeader from "../components/headers/PageHeader";
import NumberSpringBanner from "../components/banner/NumberSpringBanner";
import TwoLineTitle from "../components/titles/TwoLineTitle";
import Banner from "../components/banner/Banner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import PricingTableHosting from "../components/other/PricingTableHosting";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const scrollToHosting = () => {
    const hostingSection = document.getElementById("hosting");
    if (hostingSection) {
      hostingSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  
  const services = [
    {
      id: 1,
      name: "Web Development",
      tech: "React, Next js, Node js, WordPress",
      des: "With a rich history in web development since 2020, I excel in both front-end and back-end technologies, meticulously crafting innovative and user-friendly web solutions customized to meet the unique demands of each client. Renowned for consistently delivering top-tier results, I seamlessly integrate industry-leading expertise in React, Next.js, Node.js, and WordPress into every project, guaranteeing that each deliverable not only aligns with the latest industry standards but also surpasses client expectations with unparalleled excellence.",
      image: "assests/images/pages/services/web.jpg",
      readmore: "web-uiux",
      btnText: "View Details",
    },
    {
      id: 2,
      name: "UI/UX Design",
      tech: "Figma, Adobe XD, Adobe Illustrator, Adobe Photoshop",
      des: "Embark on a journey through the realm of UI/UX design with proficiency in Figma, Adobe XD, Illustrator, and Photoshop. As a seasoned designer, I navigate the intricate landscape of user experience and interface design, utilizing these tools to create visually stunning and intuitively functional digital experiences. From wireframing to prototyping, my expertise ensures that every design element harmonizes seamlessly to captivate users and elevate brands in today's digital era.",
      image: "assests/images/pages/services/uiux.jpg",
      readmore: "web-uiux",
      btnText: "View Details",
    },
    {
      id: 3,
      name: "Web Hosting",
      tech: "Shared Hosting, VPS, WordPress Hosting",
      des: "Navigate the labyrinth of web hosting solutions with expertise in shared hosting, VPS (Virtual Private Server), and WordPress hosting. As a seasoned professional in the web hosting landscape, I offer tailored solutions to meet diverse needs. Whether you're a burgeoning blogger, a bustling e-commerce entrepreneur, or an established enterprise, I provide the essential infrastructure and support to ensure your online presence thrives.",
      image: "assests/images/pages/services/hosting.jpg",
      readmore: "#hosting",
      btnText: "View Details",
    },
    {
      id: 4,
      name: "Graphics Design",
      tech: "Logo Design, Flyer, Broucher, Business Card, Wedding card, Stationaries, etc...",
      des: "Embark on a journey through the realm of graphic design, where creativity knows no bounds. With expertise in logo design, flyers, brochures, business cards, wedding cards, stationery, and more, I craft visually captivating assets that resonate with your audience. From branding to marketing materials, I ensure each design reflects your unique identity and leaves a lasting impression. Let's bring your vision to life and make your brand shine.",
      image: "assests/images/pages/services/graphics.jpg",
      readmore: "logodesign",
      btnText: "View Details",
    },
    {
      id: 5,
      name: "Motion Graphic Design",
      tech: "Logo Animation, Intro, Outro, Explainer Video, etc...",
      des: "Dive into the captivating world of motion graphic design, where every frame tells a story. Specializing in logo animation, intros, outros, explainer videos, and more, I bring your vision to life with dynamic visuals that leave a lasting impact. Whether it's captivating intros for your brand or engaging explainer videos for your products, I leverage motion graphics to elevate your content and capture your audience's attention. Let's animate your ideas and make them shine on screen.",
      image: "assests/images/pages/services/ae.jpg",
      readmore: "videoanimation",
      btnText: "View Details",
    },
    {
      id: 6,
      name: "Video Editing",
      tech: "Documentary Video, Business Video, Advertiesments, etc...",
      des: "Immerse yourself in the realm of video editing, where every frame tells a compelling story. With expertise in crafting documentary videos, business presentations, advertisements, and more, I transform raw footage into captivating visual narratives. Whether it's weaving together footage for a thought-provoking documentary or creating impactful advertisements for your brand, I leverage advanced editing techniques to enhance your message and captivate your audience. Let's bring your vision to life and make your videos stand out.",
      image: "assests/images/pages/services/video-editing.jpg",
      readmore: "videoanimation",
      btnText: "View Details",
    },
  ];
  return (
    <div>
      <PageHeader heading="My Services" />{" "}
      <div className="flex justify-center pt-8">
        <Banner
          url="contact"
          text="Get More Details"
          btn="Contact Me"
          subtext="I’m always open to discussing product design work or partnerships."
        />
      </div>
      <div className="flex justify-center px-[5%] md:px-[10%]">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-[1140px]  justify-center text-justify">
          {services.map((service, i) => (
            <ServiceCard
              key={i}
              name={service.name}
              tech={service.tech}
              des={service.des}
              image={service.image}
              readmore={service.readmore}
              btnText={service.btnText}
              scrollTo={service.name === "Web Hosting" ? scrollToHosting : null}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <WhatsappBanner/>
        </div>
      <NumberSpringBanner />
      <TwoLineTitle
        main="Web Hosting"
        des="Powerful and easy to control cPanel to allow our customers to take the wheel. You take the lead when it comes to what you need."
        id="hosting"
      />
      <PricingTableHosting />
    </div>
  );
};

export default Services;
