import React from 'react'


const TwoLineTitle = (props) => {
    return (

        <div className='w-full flex flex-col py-[30px] items-center text-center px-[5%] font-[poppins]'>
            
            <span className='text-4xl font-bold text-[#0052D4] uppercase font-[poppins]'>{props.main}</span>
            <div className='bg-[#0052d4] w-[100px] h-[2px] m-2'></div>   
            <span className='text-md text-[#737373] font-[poppins] pt-2 max-w-[1100px]'>{props.des}</span>   
        </div>
        );
    };
    
    export default TwoLineTitle