import React from "react";
import PageHeader from "../components/headers/PageHeader";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import ServiceCard from "../components/cards/ServiceCard";

const projects = [
  {
    id: 1,
    name: "Web Development | Sukie Ceylon",
    tech: "Wordpress, Elementor, WooCommerce, etc...",
    des: "I created a vibrant e-commerce website for Sukie Ceylon using WordPress, Elementor, and WooCommerce. The site highlights Sukie Kithul, a nutritious product sourced from the Kithul plant in the Sinharaja rainforest. Prioritizing user experience, I implemented a responsive design and optimized the site for search engines. This ensures that visitors can easily explore the rich benefits and heritage of this indigenous remedy, making it accessible and engaging for a wider audience.",
    image: "assests/images/portfolio/web/sukie-ceylon-web-01.webp",
    readmore: "http://sukieceylon.lk/",
    btnText: "View More",
  },
  {
    id: 1,
    name: "Yatanthale Plantation | 10+ Projects",
    tech: "Lable Design, Logo Design, Branding, Packaging Design, etc...",
    des: "Yatanthale Plantation is an agricultural export brand in Sri Lanka. In this project, I have worked on label design, logo design, branding, packaging design, and more. As the creative mind behind these projects, I have seamlessly blended agricultural aesthetics with modern design principles to create visually stunning and impactful branding solutions. From labels that reflect the essence of the product to logos that embody the brand's identity, each project tells a unique story of Yatanthale Plantation's journey in the global market.",
    image: "assests/images/portfolio/logo/yatanthale-plantation.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 2,
    name: "Designs For Wedding",
    tech: "Wedding Card, Cake Box, Invitation Card, etc...",
    des: "Designs for Weddings encompass a range of essential elements such as Wedding Cards, Cake Boxes, Invitation Cards, and more. As a designer specializing in wedding aesthetics, I ensure that each design radiates elegance, charm, and personalization. From intricately crafted wedding invitations that set the tone for the special day to beautifully adorned cake boxes that add a touch of sophistication, every detail is meticulously curated to create memorable experiences for the bride, groom, and their guests.",
    image: "assests/images/portfolio/wedding/wedding-1.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 3,
    name: "Social Media Post Designs | TG Technologies",
    tech: "Flyers Design - 10+",
    des: "Explore our Social Media Post Designs for TG Technologies, featuring over 10 meticulously crafted Flyers Designs. Tailored to captivate audiences on diverse social platforms, they reflect TG Technologies' dynamic essence. From captivating visuals to compelling messaging, each flyer engages and informs effectively. With a blend of creativity and strategic communication, these designs serve as powerful tools for promoting TG Technologies and driving engagement across social media channels.",
    image: "assests/images/portfolio/flyer-design/tg-main.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 4,
    name: "Logo Design | e9GIFT",
    tech: "Adobe Illustrator",
    des: "Crafting a captivating logo for e9GIFT using Adobe Illustrator requires a blend of creativity and precision. We delve into the labyrinth of design possibilities, exploring innovative concepts that embody the essence of gifting in the digital era. Our design process revolves around enhancing your brand's identity, emphasizing its unique offerings. Let your logo be a game-changer, a visual symphony that revolutionizes the gifting landscape. ",
    image: "assests/images/portfolio/logo/e9gifts.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 5,
    name: "Logo Design | Suki Ceylon",
    tech: "Adobe Illustrator",
    des: "Creating a distinctive logo for Suki Ceylon, an export brand in Sri Lanka, is a fusion of tradition and modernity. Utilizing Adobe Illustrator, we navigate the intricate tapestry of Sri Lankan culture, weaving motifs that underscore the essence of Ceylon's heritage. Our design encapsulates the hustle and bustle of export dynamics while preserving the soul of Sri Lankan craftsmanship.",
    image: "assests/images/portfolio/logo/suki-ceylon.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 6,
    name: "Fraternity | Event Design",
    tech: "Ticket Design, Social Media Post Designs, etc.. ",
    des: "Forge a sense of unity and excitement with our fraternity event designs. Utilizing Adobe Illustrator, we create dynamic ticket and flyer designs that embody the spirit of camaraderie. Vibrant and engaging, our creations capture the essence of your event, fostering anticipation and community. Ensure your gathering is a resounding success with our expertly crafted designs.",
    image: "assests/images/portfolio/tickets/ticket-1.jpg",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 6,
    name: "Social Media Management | Sukie Ceylon",
    tech: "Facebook, Instagram, Tiktok,YouTube, etc...",
    des: "Sukie Kithul, sourced from the Kithul plant in the Sinharaja rainforest, is a nutritious product with a low glycemic index and rich in vitamins and minerals. Celebrated as both a food source and traditional remedy, our social media strategy focuses on engaging content, including ticket and flyer designs, to highlight its unique benefits and cultural significance.",
    image: "assests/images/portfolio/flyer-design/Sukie-Ceylon-Social-Media-02.webp",
    readmore: "#",
    btnText: "View More",
  },
  {
    id: 3,
    name: "Social Media Post Designs | BC Express",
    tech: "Flyers Design , Video Editing",
    des: "At BC Express, we create eye-catching social media post designs that capture attention and convey key messages. Our services include flyer design and video editing, tailored to promote our logistics and transportation solutions. By integrating vibrant visuals and engaging content, we enhance brand visibility and communicate the reliability of BC Express in delivering seamless services. Let us help elevate your brand presence!",
    image: "assests/images/portfolio/flyer-design/BC-Express-Social-Media-min.webp",
    readmore: "#",
    btnText: "View More",
  },
];
const FreelanceProject = (work) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  return (
    <div>
      <PageHeader heading="Freelance Projects" />
      <div className="flex justify-center px-[5%] md:px-[10%] pt-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-[1140px]  justify-center pb-8 text-justify">
          {projects.map((work, i) => (
            // <motion.div
            //   layoutId={work.id}
            //   onClick={() => setSelectedId(work.id)}
            // >
            <ServiceCard
              name={work.name}
              tech={work.tech}
              des={work.des}
              image={work.image}
              readmore={work.readmore}
              btnText={work.btnText}
            />
            // </motion.div>
          ))}
        </div>
      </div>
      {/* <AnimatePresence>
            {selectedId && (
              <motion.div layoutId={selectedId}>
                <ProjectCard
                  name={work.name}
                  tech={work.tech}
                  des={work.des}
                  image={work.image}
                  github={work.github}
                  preview={work.preview}
                />

                <motion.button onClick={() => setSelectedId(null)} />
              </motion.div>
            )}
          </AnimatePresence> */}
    </div>
  );
};

export default FreelanceProject;
