import React from "react";
import ProjectCard from "../components/cards/ProjectCard";
import PageHeader from "../components/headers/PageHeader";
// import { motion, AnimatePresence } from "framer-motion";
import { useState ,useEffect } from "react";

const projects = [
  {
    id: 1,
    name: "Lockit | Smart Lock | 3rd Year",
    tech: "Android Studio, Kotlin, React, Node js, Arduino, MongoDB, Open AI",
    des: "LockIT is an innovative mobile application designed to revolutionize bicycle security. With a focus on user convenience and robust functionality, LockIT serves as the central control hub for the bicycle lock system we have build, providing cyclists with peace of mind and enhanced security features. Through seamless integration with major functionalities we need.",
    image: "assests/images/university-projects/lockit-bg.jpg",
    github: "https://github.com/yashEra/lockit-mobile-app",
    preview: "https://lockit-nch3.onrender.com/",
  },
  {
    id: 2,
    name: "BidWiz 2.0 | Auction Site | 3rd Year",
    tech: "React, Django, Tailwind CSS, Figma",
    des: "BidWiz 2.0, an online auction platform, facilitates users in bidding on a broad spectrum of products. Its user interface is meticulously crafted with Figma, while the web application is developed using React, Django, and Tailwind CSS. This project bears considerable significance as an extension of the previous year's BidWiz project, serving as a pivotal component in the third-year advanced programming technique module.",
    image: "assests/images/university-projects/bidwiz-2.jpg",
    github: "https://github.com/yashEra",
    preview: "https://youtu.be/a26_0VZ18S8",
  },
  {
    id: 3,
    name: "BoQ Master | 2nd Year Main Project",
    tech: "React, PHP, MySQL, Tailwind CSS",
    des: "Our project, BoQ Master, revolutionizes construction engagement by offering a platform for clients to connect with architects, Quantity Surveyors (QS), and civil engineers. Users can browse professional profiles and contact them directly through our website. BoQ Master also provides predefined architectural designs for windows and doors, allowing users to customize their projects while accurately estimating costs.",
    image: "assests/images/university-projects/boq-1.jpg",
    github: "https://github.com/yashera/boq-master",
    preview: "https://bid-wiz-client.web.app/",
  },
  {
    id: 4,
    name: "Smart assistant for a bank",
    tech: "React, Open AI, Node.js",
    des: "Completed an exciting AI Bootcamp and Hackathon with IEEE Young Professionals Sri Lanka! Explored OpenAI APIs, fine-tuning models, and AI deployment. Our challenge? Creating a bank smart assistant in just 4 hours. Utilized OpenAI's Assistant API for efficiency. Teamwork was crucial; one tuned the Assistant, another built the front-end, and the third secured the back-end. Launched on DigitalOcean and Firebase, highlighting our agility and teamwork!",
    image: "assests/images/university-projects/trast-bank.jpg",
    github: "#",
    preview: "https://www.youtube.com/watch?v=2m-UUHZyc0U",
  },
  {
    id: 5,
    name: "BidWiz 1.0 | Auction Site | 2nd Year",
    tech: "PHP, MySQL, HTML, CSS, JavaScript",
    des: "BidWiz is an online auction website that provides a platform for users to bid on a wide range of products and items. This GitHub repository contains the source code and project files for the BidWiz web application. It is designed to facilitate the development, maintenance, and collaboration of the BidWiz website.",
    image: "assests/images/university-projects/bidwiz-1.png",
    github: "https://github.com/yashEra/BidWiz-Group-E",
    preview: "https://youtu.be/UpIVXFxYg38",
  },
  {
    id: 6,
    name: "PropertyHub | REMS | 2nd Year",
    tech: "Jsp, Servlet, MySQL",
    des: "Our second-year Rapid Application Development Project focused on creating a system to automate the processes involved in property selling and buying. The Real State Management System (REMS) was developed to address the complexities of real estate tasks and provide a comprehensive and automated platform for both property sellers and property buyers. ",
    image: "assests/images/university-projects/property-hub.jpg",
    github: "https://github.com/yashEra/PropertyHub",
    preview: "#",
  },
  {
    id: 7,
    name: "Token G10 |Java Project | 1st Year",
    tech: "JavaFX, Java, Hibernate",
    des: "The ongoing crisis in Sri Lanka, marked by a severe shortage of foreign currency, has led to skyrocketing prices of essential goods, particularly food, plunging many into hunger. Fuel restrictions have cascading effects, disrupting livelihoods and causing chaos in transportation. The introduction of Token G07 aims to alleviate the lengthy fuel queues, providing a vital solution to a pressing issue.",
    image: "assests/images/university-projects/tokenG10.png",
    github: "https://github.com/yashEra/tokenG10",
    preview: "https://www.youtube.com/watch?v=EOzZ8NkFSpY",
  },
  {
    id: 8,
    name: "Western University | 1st Year",
    tech: "HTML, CSS, JavaScript",
    des: "Embark on a dynamic journey with our 1st Year Web Development Project at Western University. Utilizing HTML, CSS, and JavaScript, we're crafting an interactive virtual campus experience. Seamlessly blending design and functionality, our project promises to enhance accessibility and engagement for students, faculty, and the broader university community. Join us as we redefine the digital landscape of academia.",
    image: "assests/images/university-projects/western-university.jpg",
    github: "https://github.com/yashEra/Western-University",
    preview: "https://western-university.onrender.com/",
  },
];
const UniversityProjects = (work) => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div>
      <PageHeader heading="University Projects" />
      <div className="flex justify-center px-[5%] md:px-[10%] pt-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-[1140px]  justify-center pb-8 text-justify">
          {projects.map((work, i) => (
            // <motion.div
            //   layoutId={work.id}
            //   onClick={() => setSelectedId(work.id)}
            // >
              <ProjectCard
                name={work.name}
                tech={work.tech}
                des={work.des}
                image={work.image}
                github={work.github}
                preview={work.preview}
              />
            // </motion.div>
          ))}

        </div>
      </div>
      {/* <AnimatePresence>
            {selectedId && (
              <motion.div layoutId={selectedId}>
                <ProjectCard
                  name={work.name}
                  tech={work.tech}
                  des={work.des}
                  image={work.image}
                  github={work.github}
                  preview={work.preview}
                />

                <motion.button onClick={() => setSelectedId(null)} />
              </motion.div>
            )}
          </AnimatePresence> */}
    </div>
  );
};

export default UniversityProjects;
