import {React , useEffect} from "react";
import PageHeader from "../components/headers/PageHeader";
import Banner from "../components/banner/Banner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import PortfolioCardRect from "../components/cards/PortfolioCardRect";


const CoverDesign = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const services = [
    {
      id: 1,
      image: "assests/images/portfolio/cover/2021.jpg",
    },
    {
      id: 2,
      image: "assests/images/portfolio/cover/2019.jpg",
    },
    {
      id: 3,
      image: "assests/images/portfolio/cover/2020.jpg",
    },
    {
      id: 4,
      image: "assests/images/portfolio/cover/kingteza-2.png",
    },
    {
      id: 5,
      image: "assests/images/portfolio/cover/kingteza-1.jpg",
    },
    {
      id: 6,
      image: "assests/images/portfolio/cover/physics.jpg",
    },
    {
      id: 7,
      image: "assests/images/portfolio/tickets/ticket-1.jpg",
    },
    {
      id: 8,
      image: "assests/images/portfolio/cover/maths-book.jpg",
    },
    {
      id: 9,
      image: "assests/images/portfolio/tickets/ticket-2.jpg",
    },
  ];
  return (
    <div>
      <PageHeader heading="Cover Design" />{" "}
      <div className="flex justify-center pt-8">
        <Banner
          url="contact"
          text="Get More Details"
          btn="Contact Me"
          subtext="I’m always open to discussing product design work or partnerships."
        />
      </div>
      <div className="flex justify-center">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 max-w-[1140px] 2xl:max-w-[1140px] justify-center text-justify">
          {services.map((service, i) => (
            <PortfolioCardRect
              name={service.name}
              tech={service.tech}
              des={service.des}
              image={service.image}
              readmore={service.readmore}
              heightMain="[260px]"
              height="[250px]"
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center py-8">
        <WhatsappBanner/>
        </div>
      {/* <NumberSpringBanner /> */}
      {/* <TwoLineTitle
        main="Web Hosting"
        des="Powerful and easy to control cPanel to allow our customers to take the wheel. You take the lead when it comes to what you need."
      />
      <PricingTableHosting /> */}
    </div>
  );
};

export default CoverDesign;
