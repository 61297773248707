// import { XMarkIcon } from '@heroicons/react/20/solid'
import { AiOutlineWhatsApp } from "react-icons/ai";

export default function WhatsappBanner() {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden px-4 py-2.5 sm:px-3.5 sm:before:flex-1 bg-[white] drop-shadow-md rounded-[50px] transform hover:shadow-lg">
      <div className="flex flex-wrap items-center gap-x-2 md:gap-x-4 gap-y-2 justify-center">
        {/* <AiOutlineWhatsApp className="font-semibold text-xl md:text-3xl text-[#075e54]" /> */}
        <p className="text-sm md:text-lg leading-6 text-[#075e54]">
          <strong>Connect with Whatsapp</strong>
        </p>
        <a
          href="https://wa.me/+94788556588"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center rounded-full bg-[#25D366] px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-[#075e54] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          <AiOutlineWhatsApp className="pr-2 text-3xl" />
          Send message
          {/* <span aria-hidden="true">&rarr;</span> */}
        </a>
      </div>
    </div>
  );
}
