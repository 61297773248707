import WhatsappBanner from "../components/banner/WhatsappBanner";
import CalendlyWidget from "../components/dynamic/Calendly";
import ContactFormSection from "../components/forms/Contact";
import PageHeader from "../components/headers/PageHeader";
import TwoLineTitle from "../components/titles/TwoLineTitle";
import {React , useEffect} from "react";


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <PageHeader heading="Contact Me" />
      <div className="flex justify-center my-8">
        <WhatsappBanner className="max-w-[1140px]" />
      </div>
      <div className="w-full flex flex-col items-center justify-center"></div>
      <ContactFormSection />
      <TwoLineTitle main="Descuss about your project" des="I’m always open to discussing product design work or partnerships." />
      <CalendlyWidget/>
    </div>
  );
};

export default Contact;
