import NumberSpring from "../dynamic/NumberSpring";

const NumberSpringBanner = () => {

    return(
            <div
            className="w-full h-auto lg:h-[60vh] flex justify-center mt-8 bg-center bg-cover bg-fixed bg-[#002147]"
            style={{ backgroundImage: "url('assests/images/bg/hero-bg-2.jpg')" }}
            >
            <div className="w-full xl:w-[1140px]   2xl:w-[1440px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-12 text-white text-xl py-8">
                <div className="flex flex-col justify-center items-center w-full">
                <div className="text-6xl p-4  text-white "><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="#F0F8FF" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.87 415.87 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0a208 208 0 0 1 416 0"></path></svg></div>
                <div className="p-2 text-4xl font-bold flex"><NumberSpring n={40}/>+</div>
                <div className="uppercase text-center">Happy Customers</div>
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                <div className="text-6xl p-4  text-white "><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#F0F8FF" d="M5.575 2.074A.75.75 0 0 1 6 2.75c0 .83.312 1.308.703 1.89l.029.042c.332.494.768 1.141.768 2.068c0 .946-.477 1.78-1.204 2.275c.191.16.36.357.506.593c.476.77.698 1.947.698 3.637c0 1.712-.23 3.768-.605 5.41c-.186.816-.417 1.569-.692 2.134a3.043 3.043 0 0 1-.51.77c-.206.211-.523.436-.943.436c-.42 0-.737-.225-.942-.436a2.995 2.995 0 0 1-.51-.77c-.276-.566-.507-1.318-.693-2.135A26.05 26.05 0 0 1 2 13.254c0-1.69.222-2.866.698-3.636a2.44 2.44 0 0 1 .506-.593A2.747 2.747 0 0 1 2 6.75c0-.368 0-1.001.383-1.818c.375-.8 1.088-1.719 2.398-2.768a.75.75 0 0 1 .794-.09M3.5 6.749v.001a1.25 1.25 0 0 0 2.5 0c0-.439-.185-.743-.542-1.274l-.012-.018c-.229-.34-.5-.743-.692-1.26c-.543.552-.843 1.01-1.012 1.37c-.242.516-.242.883-.242 1.18m.474 3.657c-.248.402-.474 1.226-.474 2.849c0 1.601.218 3.545.567 5.076c.176.77.376 1.395.579 1.811c.037.077.072.141.104.195c.032-.054.067-.118.104-.195c.203-.416.403-1.042.579-1.811c.35-1.53.567-3.474.567-5.076c0-1.623-.226-2.447-.474-2.849a.786.786 0 0 0-.325-.317a1.021 1.021 0 0 0-.451-.084c-.2 0-.343.031-.451.084a.786.786 0 0 0-.325.317M10 14.918a5.957 5.957 0 0 1-1.532-.476a23.098 23.098 0 0 0 .025-1.703c.451.303.96.526 1.507.65V10.5A1.5 1.5 0 0 1 11.5 9h4a4.5 4.5 0 0 0-7.234-3.574c-.2-.553-.485-.976-.683-1.27l-.04-.06A6 6 0 0 1 17 9h3.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5zm1.5.063v4.52h9v-9h-3.69a6.004 6.004 0 0 1-5.31 4.48m3.744-4.48H11.5v2.973a4.504 4.504 0 0 0 3.744-2.973"></path></svg></div>
                <div className="p-2 text-4xl font-bold flex"><NumberSpring n={1000}/>+</div>
                <div className="uppercase text-center"><p>Oders Completed</p> <span className="text-sm">{"(Graphics Design)"}</span></div>
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                <div className="text-6xl p-4  text-white "><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="#F0F8FF" d="M3.5 3A2.5 2.5 0 0 0 1 5.5v5A2.5 2.5 0 0 0 3.5 13h5a2.5 2.5 0 0 0 2.5-2.5v-.127l2.035 1.405a1.25 1.25 0 0 0 1.96-1.028V5.252a1.25 1.25 0 0 0-1.96-1.028L11 5.629V5.5A2.5 2.5 0 0 0 8.5 3zM11 6.844l2.604-1.798a.25.25 0 0 1 .392.206v5.498a.25.25 0 0 1-.392.205L11 9.158zM2 5.5A1.5 1.5 0 0 1 3.5 4h5A1.5 1.5 0 0 1 10 5.5v5A1.5 1.5 0 0 1 8.5 12h-5A1.5 1.5 0 0 1 2 10.5z"></path></svg></div>
                <div className="p-2 text-4xl font-bold flex"><NumberSpring n={100}/>+</div>
                <div className="uppercase text-center"><p>Oders Completed</p> <span className="text-sm">{"(Video/Motion Graphics)"}</span></div>
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                <div className="text-6xl p-4  text-white "><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048"><path fill="#F0F8FF" d="m1920 1280l128 640H0l128-640h232q-51-88-77-185t-27-199q0-106 27-204t78-183t120-156t155-120t184-77t204-28q106 0 204 27t183 78t156 120t120 155t77 184t28 204q0 102-26 199t-78 185zm-256-384q0-32-3-64t-10-64h-249q6 65 6 128q0 64-6 128h249q6-32 9-64t4-64m-890 128h500q6-64 6-128q0-63-6-128H774q-6 65-6 128q0 64 6 128m482 128H792q4 23 14 60t25 81t35 86t44 78t53 57t61 22q32 0 60-22t53-57t44-78t35-86t25-80t15-61m-477 335q-46-76-74-162t-43-173H438q24 53 60 103t80 95t96 79t105 58m607-335q-16 86-43 172t-73 163q54-24 104-59t95-78t81-94t60-104zm224-512q-51-113-138-200t-203-135q46 76 73 162t44 173zm-586-384q-32 0-60 22t-53 57t-44 78t-35 86t-26 80t-14 61h464q-5-23-14-60t-25-81t-35-86t-44-78t-53-57t-61-22m-245 49q-115 48-202 135T438 640h224q16-87 43-173t74-162M397 768q-6 32-9 64t-4 64q0 32 3 64t10 64h249q-6-64-6-128q0-63 6-128zM156 1792h1736l-77-384h-220q-54 61-119 108t-139 81t-152 50t-161 17q-82 0-161-17t-152-50t-138-80t-120-109H233z"></path></svg></div>
                <div className="p-2 text-4xl font-bold flex"><NumberSpring n={30}/>+</div>
                <div className="uppercase text-center"><p>Project Completed</p> <span className="text-sm">{"(Web Development)"}</span></div>
                </div>
            </div>
            </div>

    );
};
export default NumberSpringBanner;