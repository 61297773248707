import Banner from "../components/banner/Banner";
import NumberSpringBanner from "../components/banner/NumberSpringBanner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import InfoCard from "../components/cards/ImageCard";
import VerticalCard from "../components/cards/VerticalCard";
import ContactFormSection from "../components/forms/Contact";
import Hero from "../components/hero-section/HeroSection";
import PricingTableHosting from "../components/other/PricingTableHosting";
import TechStack from "../components/tech-stack/TechStack";
import TwoLineTitle from "../components/titles/TwoLineTitle";
import {React , useEffect} from "react";


const Home = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  const services = [
    {
      id: 1,
      title: "Web Development",
      subtitle: "Portfolio, Company Profile, Blogs, WordPress, LMS (Moodle)",
      image: "assests/images/pages/services/web-1.jpg",
      url: "web-uiux",
    },
    {
      id: 2,
      title: "UI/UX Design",
      subtitle: "Figma, Adobe XD, Adobe Illustrator, Adobe Photoshop",
      image: "assests/images/pages/services/uiux-2.jpg",
      url: "web-uiux",
    },
    {
      id: 3,
      title: "Graphic Design",
      subtitle:
        "Logo Design, Flyer, Broucher, Business Card, Wedding card, Stationaries, etc...",
      image: "assests/images/pages/services/graphic-1.jpg",
      url: "services",
    },
    {
      id: 4,
      title: "Video Editing",
      subtitle: "Documentary Video, Business Video, Advertiesments, etc...",
      image: "assests/images/pages/services/video-editing-1.jpg",
      url: "video-animation",
    },
    {
      id: 5,
      title: "Motion Graphic Design",
      subtitle: "Logo Animation, Intro, Outro, Explainer Video, etc...",
      image: "assests/images/pages/services/motion-graphics-1 (2).jpg",
      url: "video-animation",
    },
    {
      id: 6,
      title: "Web Hosting",
      subtitle: "Shared Hosting, VPS, WordPress Hosting",
      image: "assests/images/pages/services/hosting-1.jpg",
      url: "services",
    },
  ];

  const myprojects = [
    {
      title: "Freelance Projects",
      subtitle:
        "Graphic Design, Web Development, UI/UX Design, Motion Graphic Design",
      image: "assests/images/pages/services/freelance.jpg",
      url: "freelance",
    },
    {
      title: "University Project",
      subtitle: "Web Development, UI/UX Design, Mobile Applications",
      image: "assests/images/pages/team.jpg",
      url: "uniprojects",
    },
    {
      title: "Individual Projects",
      subtitle:
        "Graphic Design, Web Development, UI/UX Design, Motion Graphic Design",
      image: "assests/images/pages/services/individual.jpg",
      url: "individualprojects",
    },
  ];

  const volunteering = [
    {
      id: 1,
      title: "IEEEXtreme 17.0",
      subtitle: "Ambassador, Participant, Public Visibility Team Lead",
      image: "assests/images/volunteering/ambasodor-min.jpg",
      url: "volunteer-projects",
    },
    {
      id: 2,
      title: "Impetus Magazine 2023",
      subtitle: "Magazine Design, Graphic Design, Motion Graphic Design",
      image: "assests/images/volunteering/impetus-2023.jpg",
      url: "volunteer-projects",
    },
    {
      id: 3,
      title: "Innovitriz 2023",
      subtitle: "Project Chair, Graphic Design, Motion Graphic Design",
      image: "assests/images/volunteering/innovitroz-2023.jpg",
      url: "volunteer-projects",
    },
    {
      id: 4,
      title: "CST LAN Challenge",
      subtitle:
        "Design Team Lead, Official website, Graphic Design, Motion Graphic Design",
      image: "assests/images/personal/project-5.jpg",
      url: "volunteer-projects",
    },
    {
      id: 5,
      title: "IEEE Open Day 2023",
      subtitle: "Design Team Lead, Graphic Design, Motion Graphic Design",
      image: "assests/images/volunteering/openday-2023.jpg",
      url: "volunteer-projects",
    },
    {
      id: 6,
      title: "Impetus Magazine 2022",
      subtitle: "Motion Graphic Design, Graphic Design",
      image: "assests/images/volunteering/impetus-2022.jpg",
      url: "volunteer-projects",
    },
  ];
  return (
    <div>
      <Hero />
      <div className="flex flex-col items-center bg-white w-full">
        <VerticalCard
          name="Who I am?"
          image="assests/images/pages/yasith.jpg"
          btnText="More About Me"
          btnLink="about"
          des="I am Yasith, a dedicated and experienced creative professional currently pursuing the Computer Science and Technology degree program at Uva Wellassa University Sri Lanka. With a rich background in Graphic Designing, UI/UX, Motion Graphic Design, and Web Development, I have brought a unique blend of creative flair and technical proficiency to my work. Over the years, I have honed my skills in translating ideas into visually compelling and user-friendly solutions. I am deeply passionate about the intersection of design and technology, constantly seeking innovative approaches to problem-solving. My commitment towards staying abreast of industry trends and leveraging cutting-edge tools allows me to deliver high-quality and aesthetically pleasing outcomes. In addition to my professional endeavors, I am currently enrolled in the Computer Science and Technology undergraduate program at Uva Wellassa University. This academic foundation complements my practical experience by offering a comprehensive understanding of the technological landscape."
        />
        <TwoLineTitle
          des="From designing to development, these are some skills I have been working on for the past couple of years."
          main="My Tech Stacks"
        />
        <TechStack />

        {/* My Services
         **Services Can add above services component
         */}

        <TwoLineTitle
          des="Discover the unparalleled services that I offered. And if there's anything else you need, simply reach out to us on WhatsApp and we'll be happy to assist you."
          main="My Services"
        />
        <WhatsappBanner />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 py-4 max-w-[1140px] xl:w-[1140px]">
          {services.map((service, i) => (
            <InfoCard
              title={service.title}
              subtitle={service.subtitle}
              image={service.image}
              url={service.url}
            />
          ))}
        </div>
        <Banner
          url="services"
          text="Get More Details"
          btn="Services"
          subtext="You can find out detaild description about my servicess"
        />
        <NumberSpringBanner />

        {/* Web Hosting Packages */}
        <TwoLineTitle
          main="Web Hosting"
          des="Powerful and easy to control cPanel to allow our customers to take the wheel. You take the lead when it comes to what you need."
        />
      </div>

      <PricingTableHosting className />

      {/* My Projects */}

      <div className="flex flex-col items-center bg-white w-full">
        <TwoLineTitle
          des="Find a list of my university, individual, volunteer and freelance projects attached here."
          main="My Projects"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 py-4 max-w-[1140px] xl:w-[1140px]">
          {myprojects.map((project, index) => (
            <InfoCard
              key={index}
              title={project.title}
              subtitle={project.subtitle}
              image={project.image}
              url={project.url}
            />
          ))}
        </div>

        {/* VOLUNTEERING */}

        <TwoLineTitle des="IEEE, Rotaract, FOSS" main="Volunteering" />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 py-4 max-w-[1140px] xl:w-[1140px]">
          {volunteering.map((service, i) => (
            <InfoCard
              title={service.title}
              subtitle={service.subtitle}
              image={service.image}
              url={services.url}
            />
          ))}
        </div>
        <Banner
          url="volunteer-projects"
          text="Get More Details"
          btn="Volunteering"
          subtext="My contribution as a volunteer"
        />
        <TwoLineTitle
          main="Contact Me"
          des="I’m always open to discussing product design work or partnerships."
        />
        <ContactFormSection />
      </div>
    </div>
  );
};

export default Home;
