import {
  AiFillCodepenCircle,
  } from "react-icons/ai";
  
  const ServiceCard = (props) => {
    return (
      <div className="bg-[white] drop-shadow-md rounded-xl transform hover:shadow-lg">
        {/* <a href={props.readmore} target={props.target} rel="noopener noreferrer"> */}
        <div className="p-2">
          <img
            className="h-[200px] w-full object-cover object-center rounded-t-lg hover:scale-105 duration-300"
            src={props.image}
            alt="Service Picture | yasith.art"
          />
        </div>
  
        <div className="p-4">
          <h2 className="mb-2 text-lg font-medium text-gray-900 text-start">{props.name}</h2>
          <p className="mb-2 text-base font-bold text-[#0052D4] text-start">{props.tech}</p>
          <p className="mb-2 text-sm text-gray-700">{props.des}</p>
  
          <div className="flex items-center">
            <a
              className="text-xl mr-2  font-medium  text-[#0052D4] hover:text-[#0098d4] duration-300 flex items-center justify-center"
              href={props.readmore}
              target={props.target}
              rel="noopener noreferrer"
            >
              <AiFillCodepenCircle />
              <p className="mb-2 text-sm text-[#0052D4] hover:text-[#0098d4] duration-300 pl-2 pt-2">{props.btnText}</p>
            </a>
          </div>
        </div>
        {/* </a> */}
      </div>
    );
  };
  
  export default ServiceCard;
  