import React from "react";
import IconsCard from "../cards/IconsCard";

const TechStack = () => {
  const techIcons = [
    { image: "assests/icons/reactjs.svg", tooltip: "React js" },
    { image: "assests/icons/nextjs.svg", tooltip: "Next js" },
    { image: "assests/icons/html5.svg", tooltip: "HTML 5" },
    { image: "assests/icons/javascript.svg", tooltip: "JavaScripts" },
    { image: "assests/icons/css3.svg", tooltip: "CSS 3" },
    { image: "assests/icons/tailwindcss.svg", tooltip: "Tailwind CSS" },
    { image: "assests/icons/bootstrap.svg", tooltip: "Bootstrap" },
    { image: "assests/icons/php.svg", tooltip: "PHP" },
    { image: "assests/icons/nodejs.svg", tooltip: "Node js" },
    { image: "assests/icons/expressjs.svg", tooltip: "Express js" },
    { image: "assests/icons/java.svg", tooltip: "Java" },
    { image: "assests/icons/spring.svg", tooltip: "Spring" },
    { image: "assests/icons/python.svg", tooltip: "Python" },
    { image: "assests/icons/django.svg", tooltip: "Django" },
    { image: "assests/icons/androidstudio.svg", tooltip: "Android Studio" },
    { image: "assests/icons/firebase.svg", tooltip: "Firebase" },
    { image: "assests/icons/wordpress.svg", tooltip: "Wordpress" },
    { image: "assests/icons/figma.svg", tooltip: "Figma" },
    { image: "assests/icons/illustrator.svg", tooltip: "Adobe Illustrator" },
    { image: "assests/icons/photoshop.svg", tooltip: "Adobe Photoshop" },
    { image: "assests/icons/premiere.svg", tooltip: "Adobe Premiere" },
    { image: "assests/icons/adobe-after-effects.svg", tooltip: "Adobe After Effects" },
    { image: "assests/icons/lightroom.svg", tooltip: "Adobe Lightroom" },
    { image: "assests/icons/xd.svg", tooltip: "Adobe XD" },
  ];

  return (
    <div>
      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 bg-white drop-shadow-md rounded-xl transform duration-300 hover:shadow-lg max-w-1140px m-8 p-4 items-center justify-center">
        {techIcons.map((techIcon, index) => (
          <IconsCard key={index} image={techIcon.image} tooltip={techIcon.tooltip} />
        ))}
      </div>{" "}
    </div>
  );
};

export default TechStack;
