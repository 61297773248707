const Button = ({ url, text, color, colorHover, target }) => {
  return (
    <div className="mt-4 mr-4 bg-">
      <a href={url}
          target={target}>
        <button
          className={`inline-flex items-center rounded-3xl bg-transparent px-4 py-2 text-sm font-medium text-${color} border-2 border-inset border-${color} transform hover:border-${color} hover:text-${colorHover}`}
        >
          {text}
        </button>
      </a>
    </div>
  );
};

export default Button;
