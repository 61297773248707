import { AiFillLinkedin, AiFillGithub, AiFillFacebook } from "react-icons/ai";
import emailjs from "emailjs-com";
import { useState } from "react";

const ContactFormSection = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    // Email validation regular expression
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Please enter a valid email address",
      }));

      setTimeout(() => {
        setErrors((prevState) => ({
          ...prevState,
          email: "",
        }));
      }, 3000);
      return;
    }

    emailjs
      .sendForm(
        "service_7jqdlbd",
        "template_4r6d4sm",
        e.target,
        "YMPa0BWvyFZL0bIoT"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailSent(true);

          setTimeout(() => {
            setEmailSent(false);
          }, 6000);

          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div className="flex justify-center m-4 md:m-8 ">
        <div className="max-w-[1140px] grid grid-cols-1 lg:grid-cols-2 gap-8 bg-[white] drop-shadow-md rounded-xl transform hover:shadow-lg p-8">
          {/* CONTACT FORM SECTION */}
          <div className="">
            <div className="pb-4">
              <h1 className="text-4xl font-bold text-[#0052D4] uppercase font-[poppins]">
                Get In Touch
              </h1>
              <div className="bg-[#0052d4] w-[200px] h-[2px] my-2"></div>
              <p className="text-md text-[#737373] font-[poppins] pt-2 max-w-[1100px]">
                If you have any suggestion, project or even you want to say
                Hello.. please fill out the form below and I will reply you
                shortly.
              </p>
            </div>
            <form onSubmit={sendEmail}>
              <div className="w-full grid lg:grid-cols-2 gap-4  py-[2%]">
                <input
                  className="peer block w-full appearance-none rounded-xl border-2 border-gray-500 bg-transparent py-2.5 px-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  value={formData.firstname}
                  onChange={handleChange}
                  required
                />
                <input
                  className="peer block w-full appearance-none rounded-xl border-2 border-gray-500 bg-transparent py-2.5 px-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  value={formData.lastname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="py-[2%] ">
                {errors.email && (
                  <p className="text-red-500 py-3">{errors.email}</p>
                )}
                <input
                  className="peer block w-full appearance-none rounded-xl border-2 border-gray-500 bg-transparent py-2.5 px-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="py-[2%]">
                <textarea
                  className="peer block w-full appearance-none rounded-xl border-2 border-gray-500 bg-transparent pt-2.5 pb-14 px-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                  type="textarea"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                <button
                  type="submit"
                  class="mt-5 rounded-md bg-[#1357DE] hover:bg-[#002147] px-10 py-2 text-white"
                >
                  Send Message
                </button>
                {emailSent && (
                  <p className="text-[#0052d4] pt-2">
                    Email sent successfully!
                  </p>
                )}
              </div>
            </form>
          </div>
          {/* CONTACT DETAILS SECTION */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
            <div className="grid grid-cols-1 gap-4 text-left">
              <div>
                <h2 className="text-[#1357DE] text-xl font-semibold py-2">
                  Phone
                </h2>
                <hr className="w-1/2 border-[#8EC2F2]" />
                <div className="w-full flex flex-row justify-start items-center">
                  <p className="font-bold  text-gray-500 py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 2048 2048"
                    >
                      <path
                        fill="#006eff"
                        d="M1607 1213q44 0 84 16t72 48l220 220q31 31 47 71t17 85q0 44-16 84t-48 72l-14 14q-54 54-99 96t-94 70t-109 44t-143 15q-125 0-257-39t-262-108t-256-164t-237-207t-206-238t-162-256T38 775T0 523q0-83 14-142t43-108t70-93t96-99l16-16q31-31 71-48t85-17q44 0 84 17t72 48l220 220q31 31 47 71t17 85q0 44-15 78t-37 63t-48 51t-49 45t-37 44t-15 49q0 38 27 65l551 551q27 27 65 27q26 0 48-15t45-37t45-48t51-49t62-37t79-15m-83 707q72 0 120-13t88-39t76-64t85-86q27-27 27-65q0-18-14-42t-38-52t-51-55t-56-54t-51-47t-37-35q-27-27-66-27q-26 0-48 15t-44 37t-45 48t-52 49t-62 37t-79 15q-44 0-84-16t-72-48L570 927q-31-31-47-71t-17-85q0-44 15-78t37-63t48-51t49-46t37-44t15-48q0-39-27-66q-13-13-34-36t-47-51t-54-56t-56-52t-51-37t-43-15q-38 0-65 27l-85 85q-37 37-64 76t-40 87t-14 120q0 112 36 231t101 238t153 234t192 219t219 190t234 150t236 99t226 36"
                      ></path>
                    </svg>{" "}
                  </p>
                  <p className="font-md text-gray-500 py-4 pl-4">
                    +94 78 855 6588
                  </p>
                </div>
              </div>
              <div>
                <h2 className="text-[#1357DE] text-xl font-semibold py-2">
                  Email
                </h2>
                <hr className="w-1/2 border-[#8EC2F2]" />
                <div className="w-full flex flex-row justify-start items-center">
                  <p className="font-bold text-xl text-gray-500 py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#006eff"
                        d="M2 6.038V11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2zM4 4h8a1 1 0 0 1 1 1v.74L8 8.432L3 5.74V5a1 1 0 0 1 1-1M3 6.876L7.763 9.44a.5.5 0 0 0 .474 0L13 6.876V11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z"
                      ></path>
                    </svg>
                  </p>
                  <p className="font-md text-gray-500 py-4 pl-4">
                    ya794120@gmail.com
                  </p>
                </div>
              </div>
              <div>
                <h2 className="text-[#1357DE] text-xl font-semibold py-2">
                  Location
                </h2>
                <hr className="w-1/2 border-[#8EC2F2]" />
                <div className="w-full flex flex-row justify-start items-center">
                  <p className="font-bold text-xl text-gray-500 py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        fill="#006eff"
                        d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416M512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544"
                      ></path>
                      <path
                        fill="#006eff"
                        d="M512 512a96 96 0 1 0 0-192a96 96 0 0 0 0 192m0 64a160 160 0 1 1 0-320a160 160 0 0 1 0 320"
                      ></path>
                    </svg>
                  </p>
                  <p className="font-md text-gray-500 py-4 pl-4">
                    Galle, Sri Lanka.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-[#1357DE] text-xl font-semibold py-2">
                Social Profiles
              </h2>
              <hr className="w-1/2 border-[#8EC2F2]" />
              <div className="flex items-center mt-4">
                <a
                  className="mr-4 text-4xl font-semibold  text-[#0052D4] hover:text-gray-600 hover: duration-300"
                  href="https://www.linkedin.com/in/yasitheranda"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillLinkedin />{" "}
                </a>
                <a
                  className="text-4xl mr-4  font-medium  text-[#0052D4] hover:text-gray-600 duration-300"
                  href="https://github.com/yashEra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillGithub />{" "}
                </a>
                <a
                  className="text-4xl mr-4  font-medium  text-[#0052D4] hover:text-gray-600 duration-300"
                  href="https://web.facebook.com/yash.era1998/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillFacebook />{" "}
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default ContactFormSection;
