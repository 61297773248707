import {
  AiFillGithub,
  AiOutlineGlobal,
} from "react-icons/ai";

const ProjectCard = (props) => {
  return (
    <div className="bg-[white] drop-shadow-md rounded-xl transform hover:shadow-lg">
      {/* <a href={props.portfolio} target="_blank" rel="noopener noreferrer"> */}
      <div className="p-2">
        <img
          className="h-[200px] w-full object-cover object-center rounded-t-lg hover:scale-105 duration-300"
          src={props.image}
          alt="Project Picture | yasith.art"
        />
      </div>

      <div className="p-4">
        <h2 className="mb-2 text-lg font-medium text-gray-900 text-left">{props.name}</h2>
        <p className="mb-2 text-base font-bold  text-[#0052D4] text-left">{props.tech}</p>
        <p className="mb-2 text-sm text-gray-700">{props.des}</p>

        <div className="flex items-center">
          <a
            className="text-xl mr-2  font-medium  text-[#0052D4] hover:text-[#0098d4] duration-300 flex items-center justify-center"
            href={props.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillGithub />
            <p className="mb-2 text-sm text-[#0052D4] hover:text-[#0098d4] duration-300 pl-2 pt-2">View Code</p>
          </a>
          <a
            className="ml-auto text-xl font-medium text-[#0052D4] hover:text-[#0098d4] duration-300"
            href={props.preview}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              <div>
                <AiOutlineGlobal />
              </div>
              <div>
                <p className="mb-2 text-sm text-[#0052D4] pl-2 pt-2 hover:text-[#0098d4] duration-300">Preview</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      {/* </a> */}
    </div>
  );
};

export default ProjectCard;
