import React from "react";

const ExperienceTimeline = () => {
  return (
    <div className="md:p-8 p-4">
      {/*<!-- Component: Alternative Changelog feed --> */}
      <ul
        aria-label="Alternative changelog feed"
        role="feed"
        className="max-w-[1000px] relative flex flex-col gap-12 py-12 pl-6 text-sm before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 lg:pl-0 lg:before:left-[10rem] lg:after:left-[10rem]"
      >
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2023 - Present
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2023 - Present</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              Trainee Backend Developer
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            <p className=" text-slate-500">
              CSI DevTeam - Uva Wellassa University
            </p>
            <p className=" text-slate-500">
              As a Trainee Backend Developer at Uva Wellassa University, I am
              currently serving as the team member for the development team in a
              Scrum environment. My responsibilities include crafting and
              implementing backend logic to contribute to the overall success of
              our projects.
            </p>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2020 - Present
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2020 - Present</p>
            <h3 className="text-base font-medium leading-7 text-[#0052D4]">
              Freelancer{" "}
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h3>
            <p className=" text-slate-500">Fiverr</p>
            <p className=" text-slate-500"></p>
            <ul className="pl-5 list-disc text-slate-500 marker:text-[#0052D4]">
              <li>Graphic Designer</li>
              <li>Video Ediitor</li>
              <li>Motion Graphic Designer</li>
            </ul>
          </div>
        </li>
        <li
          role="article"
          className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-[#0052D4] before:ring-2 before:ring-white lg:flex lg:gap-12 lg:pl-0 lg:before:left-[10rem]"
        >
          <h4 className="hidden text-base font-medium leading-7 text-slate-500 lg:block lg:w-36 lg:text-right">
            2021 May - Oct
          </h4>
          <div className="flex flex-col flex-1 gap-2">
            <p className=" text-slate-500 lg:hidden">2021 May - Oct</p>
            <h4 className="text-base font-medium text-[#0052D4]">
              Production Assosiate{" "}
              <span className="font-normal text-slate-500 lg:hidden"> </span>
            </h4>
            <p className=" text-slate-500">Innodata</p>
            <p className=" text-slate-500">
              Completed a 6-month contract as a school leaver and improved
              proficiency in concepts of digital data conversion, introduction
              to digital data conversion project specification, content
              conversion, and concepts of quality in digital data conversion.
            </p>
          </div>
        </li>
      </ul>
      {/*<!-- End Alternative Changelog feed --> */}
    </div>
  );
};

export default ExperienceTimeline;
