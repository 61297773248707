import {
  AiFillLinkedin,
  AiFillGithub,
  AiFillFacebook,
  AiOutlineGlobal,
} from "react-icons/ai";

const VerticalCard = (props) => {
  return (
    <div className="bg-[white] drop-shadow-md rounded-xl transform duration-300 hover:shadow-lg xl:flex xl:max-w-[1140px] m-4 md:m-8">
      <div className="p-2 xl:w-[400px]">
        <img
          className="xl:h-auto lg:w-full h-[350px] md:h-[500px] w-full  object-cover object-top xl:rounded-l-lg rounded-t-lg xl:rounded-r-none"
          src={props.image}
          alt="Card Picture | yasith.art"
        />
      </div>

      <div className="p-4 xl:w-[1000px]">
        <h2 className="mb-2 text-2xl font-medium text-gray-900">
          {props.name}
        </h2>
        <p className="mb-2 text-base text-gray-700 text-justify">{props.des}</p>
        <div className="flex items-center">
          <a
            className="mr-2 text-xl font-semibold  text-[#0052D4]"
            href={props.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin />{" "}
          </a>
          <a
            className="text-xl mr-2  font-medium  text-[#0052D4]"
            href={props.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillGithub />{" "}
          </a>
          <a
            className="text-xl  font-medium  text-[#0052D4]"
            href={props.fb}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillFacebook />{" "}
          </a>
          <a
            className="ml-auto text-xl font-medium text-[#0052D4]"
            href={props.btnLink}
            target={props.target}
            rel="noopener noreferrer"
          >
            <div className="ml-4">
              <button
                className="px-8 py-2 text-sm rounded-3xl bg-[var(--primary-blue)] text-white hover:bg-[#002147] hover:text-white"
              >
                {props.btnText}
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default VerticalCard;
