import { React, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import UniversityProjects from "./pages/UniversityProjects";
import Navbar from "./components/navbar/Navbar";
import FreelanceProject from "./pages/FreelanceProject";
import Services from "./pages/Services";
import NoPage from "./pages/NoPage";
import Footer from "./components/footer/Footer";
import PersonalProjects from "./pages/PersonalProjects";
import Contact from "./pages/Contact";
import LogoDesign from "./pages/LogoDesign";
import FlyerDesign from "./pages/FlyerDesign";
import WebAndUIUX from "./pages/WebAndUIUX";
import CoverDesign from "./pages/CoverDesign";
import WeddingDesign from "./pages/WeddingDesign";
import ProductLabelDesign from "./pages/ProductLabelDesign";
import VideoAnimation from "./pages/VideoAnimation";
import PricingTableHosting from "./components/other/PricingTableHosting";
import { AnimatePresence } from "framer-motion";
import Chatbot from "./chatbot/Chatbot";
import VolunteeringProjects from "./pages/VolunteeringProjects";

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
      showMessage(event);
    };

    const showMessage = (event) => {
      const messageDiv = document.createElement("div");
      messageDiv.textContent = "Contact: ya794120@gmail.com or 0788556588";
      messageDiv.style.position = "absolute";
      messageDiv.style.left = `${event.pageX}px`;
      messageDiv.style.top = `${event.pageY}px`;
      messageDiv.style.padding = "5px";
      messageDiv.style.background = "white";
      messageDiv.style.border = "1px solid #ccc";
      messageDiv.style.zIndex = "1000";
      messageDiv.style.padding = "10px";
      messageDiv.style.borderRadius = "10px";

      document.body.appendChild(messageDiv);

      // Remove the message after 3 seconds
      setTimeout(() => {
        document.body.removeChild(messageDiv);
      }, 3000);
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <nav>
          <Navbar />
          {/* <SiteLoader /> */}
        </nav>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/university-projects" element={<UniversityProjects />} />
            <Route path="/volunteer-projects" element={<VolunteeringProjects />} />
            <Route path="/freelance" element={<FreelanceProject />} />
            <Route path="/services" element={<Services />} />
            <Route path="/individual-projects" element={<PersonalProjects />} />
            <Route path="/logodesign" element={<LogoDesign />} />
            <Route path="/flyerdesign" element={<FlyerDesign />} />
            <Route path="/web-uiux" element={<WebAndUIUX />} />
            <Route path="/coverdesign" element={<CoverDesign />} />
            <Route path="/wedding-design" element={<WeddingDesign />} />
            <Route
              path="/productlabeldesign"
              element={<ProductLabelDesign />}
            />
            <Route path="/video-animation" element={<VideoAnimation />} />
            <Route path="/web-hosting" element={<PricingTableHosting />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </AnimatePresence>
        {/*<button
          onClick={() => setIsChatOpen(!isChatOpen)}
          className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-full"
        >
          Chat
        </button>
        {isChatOpen && <Chatbot />}*/}
        <footer>
          <Footer />
        </footer>
  </BrowserRouter>
    </div>
  );
}

export default App;
