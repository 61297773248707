import PageHeader from "../components/headers/PageHeader";
import Banner from "../components/banner/Banner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import PortfolioCardRectVideo from "../components/cards/PortfolioCardRectVideo";
import {React , useEffect} from "react";


const VideoAnimation = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  const services = [
    {
      id: 1,
      url: "https://www.youtube.com/embed/sgiQdK6fgEI",
    },
    {
      id: 2,
      url: "https://www.youtube.com/embed/6Uh53a7cVMc",
    },
    {
      id: 3,
      url: "https://www.youtube.com/embed/lId_6WvL9uk",
    },
    {
      id: 4,
      url: "https://www.youtube.com/embed/tUWkOYErvbU",
    },
    {
      id: 5,
      url: "https://www.youtube.com/embed/j9OvPqmkWd0",
    },
    {
      id: 6,
      url: "https://www.youtube.com/embed/rpJvS6D3veI",
    },
    {
      id: 7,
      url: "https://www.youtube.com/embed/YFuOjFyKoZY",
    },
    {
      id: 8,
      url: "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsacsrilanka%2Fvideos%2F1337769930401808%2F&show_text=0",
    },
    {
      id: 9,
      url: "https://www.youtube.com/embed/JmgN1BUPKX0",
    },
    {
      id: 10,
      url: "https://www.youtube.com/embed/xG0swgIutv8",
    },
    {
      id: 11,
      url: "https://drive.google.com/file/d/1hXC1vCMsDdlcjL7Pnz5kuVmBNC3GNCHx/preview",
    },
    {
      id: 12,
      url: "https://www.youtube.com/embed/n-0Q74U_x-Q",
    },
    {
      id: 13,
      url: "https://www.youtube.com/embed/HWwIz1X2hVI",
    },
    {
      id: 14,
      url: "https://www.youtube.com/embed/Ijq7foeqjtc",
    },
    {
      id: 15,
      url: "https://www.youtube.com/embed/V87DAqWdCCU",
    },
    {
      id: 16,
      url: "https://www.youtube.com/embed/qXJ0dAHUxJM",
    },
    {
      id: 17,
      url: "https://www.youtube.com/embed/Ehov8icq318",
    },
    {
      id: 18,
      url: "https://www.youtube.com/embed/6PuxnDCaZPs",
    },
    {
      id: 18,
      url: "https://www.youtube.com/embed/LaZjHh8DTko",
    },
  ];
  return (
    <div>
      <PageHeader heading="Video Editing & Animations" />{" "}
      <div className="flex justify-center pt-8">
        <Banner
          url="contact"
          text="Get More Details"
          btn="Contact Me"
          subtext="I’m always open to discussing product design work or partnerships."
        />
      </div>
      <div className="flex justify-center">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 max-w-[1140px] 2xl:max-w-[1140px] justify-center text-justify">
          {services.map((service, i) => (
            <PortfolioCardRectVideo
              name={service.name}
              tech={service.tech}
              des={service.des}
              image={service.image}
              url={service.url}
              title={service.title}
              heightMain="[260px]"
              height="[250px]"
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center py-8">
        <WhatsappBanner/>
        </div>
      {/* <NumberSpringBanner /> */}
      {/* <TwoLineTitle
        main="Web Hosting"
        des="Powerful and easy to control cPanel to allow our customers to take the wheel. You take the lead when it comes to what you need."
      />
      <PricingTableHosting /> */}
    </div>
  );
};

export default VideoAnimation;
