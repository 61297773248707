import { AiFillGithub, AiOutlineGlobal } from "react-icons/ai";
import { useState } from "react";

const VolunteeringCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <>
      <div className="bg-[white] drop-shadow-md rounded-xl transform hover:shadow-lg">
        {/* <a href={props.portfolio} target="_blank" rel="noopener noreferrer"> */}
        <div className="p-2" onClick={openModal}>
          <img
            className="h-[360px] w-[360px] object-cover object-center rounded-t-lg hover:scale-105 duration-300"
            src={props.image}
            alt="Project Picture | yasith.art"
          />
        </div>

        <div className="p-4">
          <h2 className="mb-2 text-lg font-medium text-gray-900 text-left">
            {props.name}
          </h2>
          <p className="mb-2 text-base font-bold  text-[#0052D4] text-left">
            {props.highlight}
          </p>
          <p className="mb-2 text-sm text-gray-700">{props.des}</p>
        </div>
        {/* </a> */}
      </div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative overflow-y-auto z-50">
            <div className="bg-[white] drop-shadow-md transform duration-30 hover:shadow-lg rounded-xl p-2 flex items-center">
              {" "}
              <img
                src={props.image}
                className="animate-fade-in block w-[90vh] lg:w-auto xl:h-[90vh] rounded-md scale-100 transform object-cover object-center opacity-100 transition duration-700 lg:group-hover:scale-110"
                alt="Portfolio Picture | yasith.art"
              />{" "}
              <button
                className="absolute top-4 right-4 px-8 py-2 text-sm rounded-3xl bg-red-500 text-white hover:bg-red-700 hover:text-white m-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VolunteeringCard;
