import {React , useEffect} from "react";
import PageHeader from "../components/headers/PageHeader";
import Banner from "../components/banner/Banner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import PortfolioCard from "../components/cards/PortfolioCard";


const FlyerDesign = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const designs = [
    {
      id: 1,
      image: "assests/images/portfolio/flyer-design/sokari-1.png",
    },
    {
      id: 2,
      image: "assests/images/portfolio/flyer-design/sokari-2.png",
    },
    {
      id: 3,
      image: "assests/images/portfolio/flyer-design/feternity.jpg",
    },
    {
      id: 4,
      image: "assests/images/portfolio/flyer-design/sahan.jpg",
    },
    {
      id: 5,image: "assests/images/portfolio/flyer-design/flyer-1.jpg",
    },
    {
      id: 6,
      image: "assests/images/portfolio/flyer-design/tg-1.jpg",
    },
    {
      id: 7,image: "assests/images/portfolio/flyer-design/artical.png",
    },
    {
      id: 8,
      image: "assests/images/portfolio/flyer-design/raveen-ict.jpg",
    },
    {
      id: 9,
      image: "assests/images/portfolio/flyer-design/t-shirt.jpg",
    },
    {
      id: 10,
      image: "assests/images/portfolio/flyer-design/sc-1.webp",
    },
    {
      id: 11,
      image: "assests/images/portfolio/flyer-design/sc-2.webp",
    },
    {
      id: 11,
      image: "assests/images/portfolio/flyer-design/sc-3.webp",
    },
  ];
  return (
    <div>
      <PageHeader heading="Flyer Design" />{" "}
      <div className="flex justify-center pt-8">
        <Banner
          url="contact"
          text="Get More Details"
          btn="Contact Me"
          subtext="I’m always open to discussing product design work or partnerships."
        />
      </div>
      <div className="flex justify-center">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 md:gap-8 max-w-[1140px] 2xl:max-w-[1140px] justify-center text-justify">
          {designs.map((service, i) => (
            <PortfolioCard
              name={service.name}
              tech={service.tech}
              des={service.des}
              image={service.image}
              readmore={service.readmore}
              heightMain="[380px]"
              height="[360px]"
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center py-8">
        <WhatsappBanner/>
        </div>
      {/* <NumberSpringBanner /> */}
      {/* <TwoLineTitle
        main="Web Hosting"
        des="Powerful and easy to control cPanel to allow our customers to take the wheel. You take the lead when it comes to what you need."
      />
      <PricingTableHosting /> */}
    </div>
  );
};

export default FlyerDesign;
