import React, { useState } from "react";
import { motion } from "framer-motion";

const Tooltip = ({ text, children }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}

      {show && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div className="absolute z-[100] top-[-125px] rounded-xl bg-gray-700/75 backdrop-blur-md p-4 w-[100px] text-center text-white text-xs">
            {text}
          </div>{" "}
        </motion.div>
      )}
    </div>
  );
};

export default Tooltip;
