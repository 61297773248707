import { React, useState, useEffect } from "react";
import ProjectCard from "../components/cards/ProjectCard";
import PageHeader from "../components/headers/PageHeader";
import { motion, AnimatePresence } from "framer-motion";

const projects = [
  {
    name: "Poftfolio | Multipurpose",
    tech: "React js, Tailwind CSS, Framer Motion",
    des: "Explore the dynamic Portfolio | Multipurpose project, powered by React.js, Tailwind CSS, and Framer Motion. Seamlessly blending cutting-edge technologies, this multipurpose portfolio showcases innovation and creativity. Whether you're a freelancer, entrepreneur, or creative professional, this project serves as a showcase of your work, reflecting your unique style and expertise. Elevate your online presence with the seamless integration of React.js, Tailwind CSS, and Framer Motion in the Portfolio | Multipurpose project.",
    image: "assests/images/portfolio/web/multipurpose-portfolio.jpg",
    github: "https://github.com/yashEra/my-resume",
    preview: "https://yasith-eranda.web.app/",
  },
  {
    name: "Official Website | Rotaract UWU",
    tech: "Next js, Tailwind CSS",
    des: "Introducing the Portfolio | Simple project, built with Next.js and CSS. This straightforward yet effective portfolio solution combines the power of Next.js for seamless server-side rendering with the flexibility of CSS for rapid and responsive styling. Whether you're showcasing your work or promoting your services, this simple portfolio offers a clean and minimalist design that highlights your content effortlessly. With Next.js and CSS, you can create a polished and professional portfolio that leaves a lasting impression on your audience.",
    image: "assests/images/portfolio/web/racuwu.jpg",
    github: "https://github.com/yashEra/racuwu-official",
    preview: "https://racuwu.com/",
  },
  {
    name: "Portfolio | General",
    tech: "Next js, CSS",
    des: "Introducing the Portfolio | Simple project, built with Next.js and CSS. This straightforward yet effective portfolio solution combines the power of Next.js for seamless server-side rendering with the flexibility of CSS for rapid and responsive styling. Whether you're showcasing your work or promoting your services, this simple portfolio offers a clean and minimalist design that highlights your content effortlessly. With Next.js and CSS, you can create a polished and professional portfolio that leaves a lasting impression on your audience.",
    image: "assests/images/portfolio/web/general-portfolio.jpg",
    github: "https://github.com/yashEra/cv",
    preview: "https://cv-yasith.netlify.app/",
  },
  {
    name: "Company Profille | Texyto",
    tech: "React js, CSS, Framer Motion",
    des: "Explore Company Profile | Texyto, crafted with React.js, CSS, and Framer Motion. This project combines dynamic user interfaces with stylish designs and fluid animations, showcasing your company's services, team, and achievements in an interactive and visually captivating manner. With React.js powering seamless interactions, CSS providing customized aesthetics, and Framer Motion adding smooth animations, Texyto's Company Profile elevates your online presence, ensuring your company leaves a memorable impression in the digital world.",
    image: "assests/images/university-projects/boq.jpg",
    github: "https://github.com/yashEra/texyto",
    preview: "https://texyto-client.web.app/",
  },
  {
    name: "Official Website | CST LAN Challange",
    tech: "Wordpress, Elementor",
    des: "Introducing the Official Website | CST LAN Challenge, meticulously crafted with Wordpress, Elementor. This dynamic website combines the versatility of Wordpress for content management, Elementor for intuitive design customization, and the robustness of PHP with MySQL for dynamic functionality and database management. Whether it's showcasing tournament details, registering participants, or sharing updates, this website offers a seamless experience. With its user-friendly interface and powerful features, this website serves as the ultimate platform for gaming enthusiasts to participate, compete, and connect.",
    image: "assests/images/portfolio/web/cst-lan.jpg",
    github: "#",
    preview: "https://cstlanchallenge.online/",
  },
  {
    name: "Official Webste | Rotaract UWU",
    tech: "Wordpress, Elementor, PHP, MySQL",
    des: "Introducing the Official Website | Rotaract UWU project, developed with Wordpress, Elementor, PHP, and MySQL. This comprehensive website leverages the power of Wordpress for flexible content management, Elementor for intuitive drag-and-drop design, and PHP with MySQL for dynamic functionality and database management. Whether you're promoting events, sharing resources, or engaging with members, this website offers a seamless and user-friendly experience. With its robust features and customizable design, the Official Website | Rotaract UWU serves as a central hub for the Rotaract UWU community to connect, collaborate, and thrive.",
    image: "assests/images/portfolio/web/rac-uwu.jpg",
    github: "#",
    preview: "https://racuwu.com/",
  },
  // {
  //   id: 6,
  //   name: "Token G10 |Java Project | 1st Year",
  //   tech: "JavaFX, Java, Hibernate",
  //   des: "The ongoing crisis in Sri Lanka, marked by a severe shortage of foreign currency, has led to skyrocketing prices of essential goods, particularly food, plunging many into hunger. Fuel restrictions have cascading effects, disrupting livelihoods and causing chaos in transportation. The introduction of Token G07 aims to alleviate the lengthy fuel queues, providing a vital solution to a pressing issue.",
  //   image: "assests/images/university-projects/tokenG10.png",
  //   github: "https://github.com/yashEra/tokenG10",
  //   preview: "#",
  // },

];
const PersonalProjects = (work) => {
  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div>
      <PageHeader heading="Individual Projects" />
      <div className="flex justify-center px-[5%] md:px-[10%] pt-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-[1140px]  justify-center pb-8 text-justify">
          {projects.map((work, i) => (
            // <motion.div
            //   layoutId={work.id}
            //   onClick={() => setSelectedId(work.id)}
            // >
              <ProjectCard
                name={work.name}
                tech={work.tech}
                des={work.des}
                image={work.image}
                github={work.github}
                preview={work.preview}
              />
            // </motion.div>
          ))}

        </div>
      </div>
      {/* <AnimatePresence>
            {selectedId && (
              <motion.div layoutId={selectedId}>
                <ProjectCard
                  name={work.name}
                  tech={work.tech}
                  des={work.des}
                  image={work.image}
                  github={work.github}
                  preview={work.preview}
                />

                <motion.button onClick={() => setSelectedId(null)} />
              </motion.div>
            )}
          </AnimatePresence> */}
    </div>
  );
};

export default PersonalProjects;
