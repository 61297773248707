import { AiFillLinkedin, AiFillGithub, AiFillFacebook } from "react-icons/ai";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Button from "../buttons/Button";
// import { Parallax } from "react-parallax";

const Hero = () => {
  const [text] = useTypewriter({
    words: ["Full Stack Developer", "Graphic Designer ", "Video Editor"],
    loop: true,
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <div className="">
      {/* <Parallax bgImage="assests/images/bg/bg-metaverse.jpg" strength={600}> */}
        {/* blur={{min: -5, max: 15}} */}
        <div className="w-full h-[100vh] top-[90px] flex items-center justify-center bg-center bg-cover bg-fixed bg-[#002147]"
        style={{ backgroundImage: "url('assests/images/bg/hero-bg-min.jpg')" }}>
          {/* <img
          className="object-cover items-start h-[100vh] w-full absolute -z-10 z-[-1]"
          src="assests/images/bg/bg-metaverse.jpg"
          autoPlay
          loop
          muted
        /> */}
          {/* Content */}
          <div className="w-[full] flex flex-col-reverse xl:flex-row justify-between items-center max-w-[1140px] lg:w-[1140px]">
            <div className="lg:w-[50%] mb-8 md:mb-1 flex flex-col justify-center items-left text-white text-left md:mt-8">
              <h1 className="py-2 font-black text-3xl lg:text-6xl uppercase font-[Poppins]">
                Hi, I'm
              </h1>
              <h1 className="py-2 font-black text-3xl lg:text-6xl uppercase font-[Poppins]">
                Yasith{" "}
                <span
                  className="font-[Poppins]"
                  style={{
                    WebkitTextStrokeWidth: "1px",
                    WebkitTextStrokeColor: "white",
                    color: "transparent",
                  }}
                >
                  Eranda
                </span>
              </h1>

              <h4 className="py-2 font-bold text-xl lg:text-2xl">
                I am a <span className="text-[#0052D4]">{text}</span>
                <Cursor />
              </h4>
              <div className="flex items-center mt-4">
                <a
                  className="mr-4 text-4xl font-semibold  text-white hover:text-[#0052D4] duration-300"
                  href="https://www.linkedin.com/in/yasitheranda"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillLinkedin />{" "}
                </a>
                <a
                  className="text-4xl mr-4  font-medium  text-white hover:text-[#0052D4] duration-300"
                  href="https://github.com/yashEra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillGithub />{" "}
                </a>
                <a
                  className="text-4xl mr-4  font-medium  text-white hover:text-[#0052D4] duration-300"
                  href="https://web.facebook.com/yash.era1998/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillFacebook />{" "}
                </a>
              </div>
              <div className="flex items-center mt-4">
                <Button
                  url="services"
                  text="My Services"
                  color="#0052D4"
                  hoverColor="blue"
                  textColor="blue"
                />
                <Button
                  text="My Resume"
                  url="assests/pdf/yasith-eranda-cv.pdf"
                  color="#0052D4"
                  hoverColor="blue"
                  textColor="blue"
                  target="_blank"
                />
              </div>
            </div>
            {/* Image */}
            <div className="lg:w-[50%]">
              <img
                className="object-cover items-end h-[40vh] lg:h-full w-full -z-10 z-[-1]"
                src="assests/mainIconsdark.svg"
                alt="Hero Icons | yasith.art"
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        </div>
      {/* </Parallax> */}
    </div>
  );
};

export default Hero;
