import recat, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
// import Logo from "../../assets/images/logo.svg";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showProjectsDropdown, setShowProjectsDropdown] = useState(false);
  const [showPortfolioDropdown, setShowPortfolioDropdown] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  const handleProjectsDropdown = () => {
    setShowProjectsDropdown(!showProjectsDropdown);
  };
  const handlePortfolioDropdown = () => {
    setShowPortfolioDropdown(!showPortfolioDropdown);
  };
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const projects = [
    {
      linkName: "University Projects",
      // icon: dashboardSVG,
      sortDescription: "Acadamic Projects",
      link: "university-projects",
    },
    {
      linkName: "Individual Projects",
      sortDescription: "Personal Projects",
      link: "individual-projects",
    },
    {
      linkName: "Freelance Projects",
      sortDescription: "Web, Graphic, Video Editing",
      link: "freelance",
    },
    {
      linkName: "Volunteering Projects",
      sortDescription: "IEEE, Rotaract, FOSS",
      link: "volunteer-projects",
    },
  ];
  const navigationItems = [
    {
      linkName: "Web & Ui/UX Design",
      sortDescription: "Live Preview",
      link: "web-uiux",
    },
    {
      linkName: "Video Editing & Animation",
      sortDescription: "Packets, Bottles, Boxes, Tickets etc.",
      link: "video-animation",
    },
    {
      linkName: "Logo Design & Branding",
      sortDescription: "Modern Logo, Business Card, etc.",
      link: "logodesign",
    },
    {
      linkName: "Flyer Deign",
      sortDescription: "Minimal, Mascot Logo Design",
      link: "flyerdesign",
    },
    {
      linkName: "Wedding Stationery Design",
      sortDescription: "Wedding Card, Cake Box, etc.",
      link: "wedding-design",
    },
    {
      linkName: "Cover Design",
      sortDescription: "Book, Magazine, Calander, etc.",
      link: "coverdesign",
    },
    {
      linkName: "Product Label Design & Ticket Design",
      sortDescription: "Packets, Bottles, Boxes, Tickets etc.",
      link: "productlabeldesign",
    },
  ];
  return (
    <div
      className={`w-full h-[70px] md:h-[70px] font-bold z-30 fixed text-white${
        scrollPosition > 100
          ? "duration-500 bg-white/90 backdrop-blur-md text-[var(--primary-blue)]"
          : ""
      }`}
    >
      <div className="max-w-[900px] xl:max-w-[1140px] lg:mx-auto px-4 flex justify-between items-center h-full mx-6">
        <div className=" ">
          <Link to="/">
            {/* Yasith Eranda */}
            {/*<img className="h-[40px]" src="assests/images/logo/logo-2.svg" />*/}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.3 199.58" className="h-[40px]" fill={scrollPosition > 100 ? "var(--primary-blue)" : "white"}>
            <path class="cls-1" d="M192.28,118.16c-14.57-12.75-19.22-20.23-19.22-30.54,0-9.71,4.05-13.55,11.12-13.55s11.13,3.84,11.13,14V95.3h21V89.44c0-22.66-10.72-35.6-32.36-35.6S150.81,66.78,150.81,89c0,16.18,6.07,27.71,24.68,44.09,14.56,12.74,19,20.23,19,32,0,10.51-4.45,14.16-11.53,14.16s-11.53-3.65-11.53-13.76v-9.71h-21v8.09c0,22.66,10.92,35.6,32.76,35.6s33.58-12.94,33.58-36C216.75,145.86,210.88,134.54,192.28,118.16Zm37.21,79.28h22.25V55.86H229.49Zm33-121.35h23.26V197.44H308V76.09h23.26V55.86H262.45ZM145.37,33.4A45.13,45.13,0,0,0,101.86,0,53.46,53.46,0,0,0,48.48,53.42c0,28.76,23.34,52.71,52,53.38q.33,0,.66,0h.11l.49,0h.19l.29,0h.14a29.57,29.57,0,0,1,29.09,29.47v27a29.48,29.48,0,0,1-58.92,1.49l-.14-2.74H65.81l-.14,2.74a29.47,29.47,0,0,1-58.91-1.49V63.82H0v99.52a36.24,36.24,0,0,0,69.09,15.31A36.1,36.1,0,0,0,102,199.58c18.14,0,34.17-14.55,36-32.57h.2V136.32a36.24,36.24,0,0,0-29.94-35.68,36.29,36.29,0,0,0-5.88-.56h-.51a46.66,46.66,0,0,1,0-93.32A38.38,38.38,0,0,1,138.33,33.4H127.72a28.41,28.41,0,0,0-25.86-16.72,36.74,36.74,0,0,0,0,73.48,45.11,45.11,0,0,0,45.05-45.08,47.1,47.1,0,0,0-.27-4.92H410.3V33.4Zm-22.46,6.76a21.63,21.63,0,0,1-21,26.56,13.3,13.3,0,0,1-13.28-13.3,13.17,13.17,0,0,1,1.74-6.56A11.71,11.71,0,0,0,99,56.41v.39h2.88a11.69,11.69,0,0,0,10.62-16.64Zm-26,4.93A4.93,4.93,0,1,1,101.86,50,4.93,4.93,0,0,1,96.93,45.09Zm4.93,38.31a30,30,0,0,1,0-60,21.62,21.62,0,0,1,18.19,10H102.67c-.27,0-.54,0-.81,0a20.06,20.06,0,0,0,0,40.12,28.4,28.4,0,0,0,28-33.32h10a38.23,38.23,0,0,1-38,43.24Zm286.19,30.1H364.19V55.86H341.94V197.44h22.25V133.73h23.86v63.71H410.3V55.86H388.05Z"/>
      </svg>
          </Link>
        </div>
        <div>
          <div className="hidden md:flex">
            <ul className="flex items-center font-semibold">
              <li className="p-4 hover:text-[#9de3ff] duration-300">
                <Link to="services">Services</Link>
              </li>
              <li className="p-4 hover:text-[#9de3ff] duration-300">
                <Link to="about">About Me</Link>
              </li>
              <li
                className="p-4"
                onMouseEnter={handleProjectsDropdown}
                onMouseLeave={handleProjectsDropdown}
              >
                <span className="flex items-center hover:text-[#9de3ff] duration-300">
                  Projects
                  <FaChevronDown className="pl-2 text-xl" />
                </span>
                {showProjectsDropdown && (
                  <div className="absolute bg-[white] drop-shadow-lg rounded-xl py-2 mt-2 transition duration-1000 ease-in-out">
                    {projects.map((item, index) => (
                      <Link
                        className="flex items-center px-8 py-2 text-[#002147] hover:text-[#0052D4] hover:bg-blue-200 duration-300"
                        to={item.link}
                      >
                        <div>
                          <h2 className="block py-1">{item.linkName}</h2>
                          <p className="block text-sm font-medium">
                            {item.sortDescription}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </li>
              <li
                className="p-4"
                onMouseEnter={handlePortfolioDropdown}
                onMouseLeave={handlePortfolioDropdown}
              >
                <span className="flex items-center hover:text-[#9de3ff] duration-300">
                  Portfolio
                  <FaChevronDown className="pl-2 text-xl" />
                </span>
                {showPortfolioDropdown && (
                  <div className="absolute bg-[white] drop-shadow-lg rounded-xl py-2 mt-2 transition duration-1000 ease-in-out">
                    {navigationItems.map((item, index) => (
                      <Link
                        className="flex items-center px-8 py-2 text-[#002147] hover:text-[#0052D4] hover:bg-blue-200 duration-300"
                        to={item.link}
                      >
                        <div>
                          <h2 className="block py-1">{item.linkName}</h2>
                          <p className="block text-sm font-normal">
                            {item.sortDescription}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </li>
            </ul>
          </div>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2 md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <AiOutlineMenu className="h-8 w-8 flex items-center pt-2" aria-hidden="true" />
          </button>
          <Dialog
            as="div"
            className="lg:hidden z-50"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="-m-2.5 rounded-md p-2.5 text-[#002147]" />
            <Dialog.Panel className="fixed inset-y-0 right-0 w-full z-50 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 text-[#002147]">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only -m-2.5 rounded-md p-2.5 text-[#0052d4] text-base font-semibold">
                    Yasith Eranda
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.3 199.58" className="h-[40px] " fill="#002147">
            <path class="cls-1" d="M192.28,118.16c-14.57-12.75-19.22-20.23-19.22-30.54,0-9.71,4.05-13.55,11.12-13.55s11.13,3.84,11.13,14V95.3h21V89.44c0-22.66-10.72-35.6-32.36-35.6S150.81,66.78,150.81,89c0,16.18,6.07,27.71,24.68,44.09,14.56,12.74,19,20.23,19,32,0,10.51-4.45,14.16-11.53,14.16s-11.53-3.65-11.53-13.76v-9.71h-21v8.09c0,22.66,10.92,35.6,32.76,35.6s33.58-12.94,33.58-36C216.75,145.86,210.88,134.54,192.28,118.16Zm37.21,79.28h22.25V55.86H229.49Zm33-121.35h23.26V197.44H308V76.09h23.26V55.86H262.45ZM145.37,33.4A45.13,45.13,0,0,0,101.86,0,53.46,53.46,0,0,0,48.48,53.42c0,28.76,23.34,52.71,52,53.38q.33,0,.66,0h.11l.49,0h.19l.29,0h.14a29.57,29.57,0,0,1,29.09,29.47v27a29.48,29.48,0,0,1-58.92,1.49l-.14-2.74H65.81l-.14,2.74a29.47,29.47,0,0,1-58.91-1.49V63.82H0v99.52a36.24,36.24,0,0,0,69.09,15.31A36.1,36.1,0,0,0,102,199.58c18.14,0,34.17-14.55,36-32.57h.2V136.32a36.24,36.24,0,0,0-29.94-35.68,36.29,36.29,0,0,0-5.88-.56h-.51a46.66,46.66,0,0,1,0-93.32A38.38,38.38,0,0,1,138.33,33.4H127.72a28.41,28.41,0,0,0-25.86-16.72,36.74,36.74,0,0,0,0,73.48,45.11,45.11,0,0,0,45.05-45.08,47.1,47.1,0,0,0-.27-4.92H410.3V33.4Zm-22.46,6.76a21.63,21.63,0,0,1-21,26.56,13.3,13.3,0,0,1-13.28-13.3,13.17,13.17,0,0,1,1.74-6.56A11.71,11.71,0,0,0,99,56.41v.39h2.88a11.69,11.69,0,0,0,10.62-16.64Zm-26,4.93A4.93,4.93,0,1,1,101.86,50,4.93,4.93,0,0,1,96.93,45.09Zm4.93,38.31a30,30,0,0,1,0-60,21.62,21.62,0,0,1,18.19,10H102.67c-.27,0-.54,0-.81,0a20.06,20.06,0,0,0,0,40.12,28.4,28.4,0,0,0,28-33.32h10a38.23,38.23,0,0,1-38,43.24Zm286.19,30.1H364.19V55.86H341.94V197.44h22.25V133.73h23.86v63.71H410.3V55.86H388.05Z"/>
      </svg>
                  {/* className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              /> */}
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-[#002147]"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <AiOutlineClose className="h-6 w-6 font-semibold" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-[#002147] hover:bg-gray-50">
                            Projects
                            <FaChevronDown
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {projects.map((item) => (
                              <Disclosure.Button
                                key={item.linkName}
                                as="a"
                                href={item.link}
                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-[#002147] hover:bg-gray-50"
                              >
                                {item.linkName}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-[#002147] hover:bg-gray-50">
                            Portfolio
                            <FaChevronDown
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {navigationItems.map((item) => (
                              <Disclosure.Button
                                key={item.linkName}
                                as="a"
                                href={item.link}
                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-[#002147] hover:bg-gray-50"
                              >
                                {item.linkName}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <a
                      href="services"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#002147] hover:bg-gray-50"
                    >
                      Services
                    </a>
                    <a
                      href="about"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#002147] hover:bg-gray-50"
                    >
                      About Me
                    </a>
                  </div>
                  <div className="py-6">
                    <a
                      href="contact"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-[#002147] hover:bg-gray-50"
                    >
                      Hire Me
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
            </Dialog>
          </div>
        <div className="hidden md:flex ml-4">
          <Link to="contact">
            <button
              className={`bg-[var(--primary-blue)] px-8 py-2 rounded-3xl ${
                scrollPosition > 100
                  ? "bg-[var(--primary-blue)] text-white hover:bg-[#002147] hover:text-white"
                  : "bg-white text-[var(--primary-blue)] hover:bg-[var(--primary-blue)] hover:text-white"
              }`}
              // style={{ maxHeight: "calc(100vh - 69px)", overflowY: "auto" }}
            >
              Hire Me
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
