

export default function Banner(banner) {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden px-6 mx-8 py-2.5 mb-8 sm:px-3.5 sm:before:flex-1 justify-center bg-[white] drop-shadow-md md:rounded-[50px] rounded-xl transform hover:shadow-lg">
      <div className="max-w-[1140px] flex flex-wrap items-center gap-x-2 md:gap-x-4 gap-y-2 justify-around">
        
        <p className="text-sm md:text-lg leading-6 text-[#757575] text-center">
          <strong>{banner.text}</strong> {banner.subtext}
        </p>
        <a
          href={banner.url}
          className="flex-none rounded-full bg-[#0052D4] px-3.5 py-1 text-md font-semibold text-white shadow-sm hover:bg-[#002147] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          {banner.btn}
          <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  );
}
